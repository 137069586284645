<template>
        <section class="mb-10 solutions-holder">   
            <v-layout justify-space-around justify-center>
                <v-flex xs9 sm9 offset-sm1 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent">
                             <v-card-title primary-title class="justify-center mb-6 px-0">
                                    <h3 v-if="!is_screen_small" class="zamu-heading transact-heading  slogan-heading mb-3">Our Partners </h3><br />
                                    <h3 v-if="is_screen_small" class="zamu-heading transact-heading  slogan-heading mb-3">Our <br />Partners </h3><br />

                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center transact-underline ">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row align="center" justify="center" class="my-3">
                                <v-col cols="12"  lg="6" md="6" sm="6" xs="12" class="" v-for="(partner, index) in partners" :key="index">
                                    <v-card  flat color="transparent"  height=""  align="left" justify="left" class="pa-1" >
                                        <a target="_blank" :href="partner.link">

                                            <v-img :src="require(`../../../../assets/images/partners/${partner.logo}`)" width="50%"  class="" :key="index"></v-img>
                                        </a>
                                    </v-card>
                                </v-col>
                            </v-row>


                        </v-card>
                </v-flex>
            </v-layout>
        </section>
</template>
<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Partners',
    mixins: [colorMixin, coreMixin],
    computed: {
        partners () {
            const data = [
                {
                    logo: 'pesapepe.png',
                    link: ' https://pesapepe.co.ke/'
                },
                {
                    logo: 'Centrino Logo.png',
                    link: ' https://centrino.co.ke/'

                }
        
                
            ];
            return data;

    },
    }
   

}
</script>

                            